import React, {Component} from 'react';
import NewsEntry from './../components/news-entry.component.js';
import NewsEntryEndpoints from './../components/util/news-entry-endpoints.component.js';

class Home extends React.Component{

	constructor(props){
		super(props);
		this.state = {};
	}

	componentDidMount(){
		let entries = [];

		NewsEntryEndpoints.getTest().then(resp => { 
			for(let data of resp.data){
				entries.push(data);
			}
			this.setState({newsEntries : entries});
			console.log(this.state.newsEntries);
		});
	}

	renderNewsItems(){
		if(this.state && this.state.newsEntries){
			return this.state.newsEntries.map((item) => {
				return <NewsEntry data={item}/>
			})
		}
	}

	render(){
		

		return (
			<div className="news-wrapper">
				<div className="news-header">
					<h5>NEWS/UPDATES</h5>
				</div>
				<div className="news-entry-wrapper">
					{this.renderNewsItems()}
				</div>
			</div>
		);
	}
}

export default Home;