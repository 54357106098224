import React from 'react';
import CrookLogo from '../assets/images/logo.png';
import CrookNav from './crook-nav.component.js';

function crookHeader(){
	return (
		<div className="header-wrapper">
			<div className="crook-header d-none d-md-inline-block">
				<div className="logo-wrapper">
					<div className="logo"><img src={CrookLogo} alt="Crystal Rook Arts"/></div>
					<div className="logo-text">Crystal Rook Arts</div>
				</div>
			</div>
			
			<CrookNav></CrookNav>
		</div>
	);
}

export default crookHeader;