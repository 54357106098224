import React from 'react';
import CrookApi from './crook-api.component';

class ComicSeriesEndpoints extends React.Component {

	static getComicSeries(seriesId){
		return CrookApi.get("/series/" + seriesId);
	}

	static getComicCharacters(seriesId){
		return CrookApi.get("/series/" + seriesId + "/characters");
	}

}

export default ComicSeriesEndpoints;
