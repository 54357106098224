import React, { useState } from 'react';
import SysInfo from './Sys-Info.component.js';

class CopyrightFooter extends React.Component{
	constructor(){
		super();
	}

	componentDidMount(){
		if(SysInfo.getInstance().sysInfo){
			SysInfo.getInstance().sysInfo.then(data => {
				this.setState({copyright: data.data[0].value});
			});
		}
	}

	render() {
		return (
			<div className="d-flex justify-content-center copyright">
	            © {this.state && this.state.copyright ? this.state.copyright : ""} Crystal Rook Arts, All Rights Reserved
	        </div>
		);
	}
}

export default CopyrightFooter;