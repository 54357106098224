import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import CrookNavItem from './crook-nav-item.component.js';
import Collapse from 'react-bootstrap/Collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function CrookNav() {
	const [open, setOpen] = useState(false);

	return (
		<div>
			<div className="hamburger d-inline-block d-md-none" onClick={() => setOpen(!open)}>
				<FontAwesomeIcon icon={faBars} size="3x"/>
			</div>
			<div className="nav-wrapper d-flex d-md-none pl-4">
				<Collapse in={open}>
					<Row className="flex-column flex-md-row justify-content-start pl-2 flex-nowrap">
						<CrookNavItem label="Home" navLink="/home" onClick={() => setOpen(!open)}></CrookNavItem>
						<CrookNavItem label="Space Pirate" navLink="/spacepirate/about" onClick={() => setOpen(!open)}>></CrookNavItem>
						<div className="nav-item"><a href="http://etsy.spacepiratemanga.com" target="_blank" onClick={() => setOpen(!open)}>SHOP</a></div>
					</Row>
				</Collapse>
			</div>

			<div className="nav-wrapper d-none d-md-flex pl-4">
				<Row className="flex-column flex-md-row justify-content-start pl-4">
					<CrookNavItem label="Home" navLink="/home"></CrookNavItem>
					<CrookNavItem label="Space Pirate" navLink="/spacepirate/about"></CrookNavItem>
					<div className="nav-item"><a href="http://etsy.spacepiratemanga.com" target="_blank">SHOP</a></div>
				</Row>
			</div>

		</div>
	);
}

export default CrookNav;