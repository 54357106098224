import React from 'react';
import ListingCard from './../components/listing-card.component.js';
import ShopEndpoints from './../components/util/shop-endpoints.component.js';

class Shop extends React.Component {

	constructor(){
		super();

		this.renderShopItems = this.renderShopItems.bind(this);
	}

	componentDidMount(){
		ShopEndpoints.getShopListings().then(data => {
			this.setState({items : data.data});
		});
	}

	renderShopItems(){
		let cards = [];
		if(this.state && this.state.items){
			for(var i = 0; i < this.state.items.length; i++){
				cards.push(<ListingCard item={this.state.items[i]}/>);
			}
		}

		return cards;
	}

	render(){
		return (
			<div class="news-wrapper d-flex flex-wrap pl-3 pr-3">
				{this.renderShopItems()}
			</div>
		)
	}
}

export default Shop;