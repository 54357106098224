import React from 'react';
import CrookApi from './crook-api.component';

class ContentStreamEndpoints extends React.Component {

	static getRootEndpoint(){
		return "contentstream/";
	}

	static getEpisodePageImage(episodeId, pageNo){
		return CrookApi.get(this.getRootEndpoint() + "episode/" + episodeId + '/page/' + pageNo);
	}

}

export default ContentStreamEndpoints;