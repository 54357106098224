import React from 'react';
import SysInfoEndpoints from './util/sys-info-endpoints.component.js';

export default class SysInfo {
	static instance = null;
	sysInfo = null;

	static getInstance(){
		if(SysInfo.instance == null){
			SysInfo.instance = new SysInfo();
		}

		return this.instance;
	}

	init() {
		this.sysInfo = SysInfoEndpoints.getSysInfo();
	}
}