import React from 'react';
import CrookApi from './crook-api.component.js';

class ShopEndpoints extends React.Component {

	static getRootEndpoint(){
		return "/shop";
	}

	static getShopListings(){
		return CrookApi.get(this.getRootEndpoint() + "/");
	}


}

export default ShopEndpoints;