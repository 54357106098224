import React, {Component} from 'react';
import Image from 'react-bootstrap/Image';
import NewsAttachment from './news-attachment.component.js';


class NewsEntry extends Component {

	renderAttachments(){
		let attachments = [];

		for(let attach of this.props.data.attachments){
			attachments.push(<NewsAttachment attachment={attach}/>)
		}

		return attachments;
	}

	render(){
		return (
			<div className="news-entry ml-5 mr-5">
				<div className="news-head">
					<h6>
						<span>{this.props.data.title}</span>
					 	<span className="d-none d-sm-inline">&nbsp;-&nbsp;</span> 
					 	<span className="d-block d-sm-inline">{this.props.data.postDateAsString}</span>
					 </h6>
				</div>
				<div className="news-body">
					<div>
						{this.props.data.content}
					</div>
				</div>
				<div className="news-attachments">
					{this.renderAttachments()}
				</div>
			</div>
		);
	}

}

export default NewsEntry;