import React from "react";
import EpisodeEntry from './../../components/space-pirate/episode-entry.component.js';
import EpisodeEndpoints from './../../components/util/episode-endpoints.component.js';
import {
  BrowserRouter as Router,
  Switch,
  Route, Link
} from "react-router-dom";

class SpacePirateRead extends React.Component {
	constructor() {
		super();
		this.state = {episodes : null, latestEpisode: null};
	}

	componentDidMount(){
		EpisodeEndpoints.getEpisodesBySeries(1).then(resp => { 
			var entries = [];
			for(let data of resp.data){
				entries.push(data);
			}
			let state = this.state;
			state.episodes = entries;
			this.setState(state);
		});

		EpisodeEndpoints.getLatestEpisodeMetadata(1).then(resp => {
			let state = this.state;
			state.latest = resp.data;
			this.setState(state);
		});
	}

	renderEpisodes(){
		if(this.state && this.state.episodes){
			return this.state.episodes.map((item) => {
				return <EpisodeEntry episode={item}/>
			})
		}
	}

	render (){
		return (
			<div>
				{this.state.latest && 
				<div className="latest-episode-wrapper">
					<h4><Link to={"episode/" + this.state.latest.id}>{"JUMP TO LATEST EPISODE - " + this.state.latest.postDateString}</Link></h4>
					<h6>{"#" + this.state.latest.episodeNumber + " " + this.state.latest.episodeTitle}</h6>
				</div>
				}

				<div className="episode-list-wrapper">
					{this.renderEpisodes()}
				</div>
			</div>
		)
	}
}

export default SpacePirateRead;