import React from "react";

function AboutRoute(){
	return (
		<div className="news-wrapper">
			<div className="news-header">
				<h5>COMPANY INFO</h5>
			</div>
			<div className="news-entry-wrapper">
				<div className="news-entry ml-5 mr-5">
					<p>CrystalRook Arts was founded in 2019. It was created to publish and distribute its flagship IP, Space Pirate, an independent American manga series.</p>

					<p>So what is Space Pirate, you ask?</p>

					<p>Space Pirate is a manga series created in the US. It was originally an RPG Maker game for Playstation in 2002 - and was quite possibly the worst thing
					   we have ever produced. In 2017, we started exploring relaunching the game with a revamped story. However, due to delays in deciding on art style and
					   what type of game to make it, we ditched the idea and went with a manga series. Now not only do we not have to worry about coding something,
					   but we can also get content out to you, the reader, on a regular basis.</p>

					<p>So sit back, grab some tea (or whatever your beverage of preference is) and get reading! And if you really really liked what you read, you can
					   head over to our Etsy shop to grab a hard copy!</p>
				</div>
			</div>
		</div>
	);
}

export default AboutRoute;