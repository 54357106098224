import React from "react";
import Image from 'react-bootstrap/Image';
import CharacterMeta from './../../components/space-pirate/character-meta.component.js';
import ComicSeriesEndpoints from "./../../components/util/comic-series-endpoints.component.js";

class SpacePirateCharacters extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount(){
		let state = this.state;
		ComicSeriesEndpoints.getComicCharacters(1).then(data => {
			state.characters = data.data;
			this.setState(state);
		});
	}


	renderCharacters(){
		let characters = [];

		if(this.state !== null && this.state.characters){
			for(let character of this.state.characters){
				characters.push(<CharacterMeta character={character}/>);
			}
		}

		return characters;
	}

	render (){
		return (
			<div className="character-wrapper-main d-flex justify-content-center flex-column flex-lg-row align-items-center">
				{this.state && 
				 this.state.characters && 
				 this.renderCharacters()}
			</div>
		)
	}
}

export default SpacePirateCharacters;