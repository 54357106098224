import React, {Component} from 'react';
import Image from 'react-bootstrap/Image';
import TapasLogo from '../assets/images/tapas.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import {
  BrowserRouter as Router,
  Switch,
  Route, Link
} from "react-router-dom";
import SpacePirateAbout from "./spacepirate/about.route.js";
import SpacePirateRead from "./spacepirate/read.route.js";
import SpacePirateCharacters from "./spacepirate/characters.route.js";
import ReadComic from "./../components/common/read/read-comic.component.js";

class SpacePirate extends React.Component {

	constructor(props){
		super(props);
		this.state = {};
	}

	componentDidMount(){
		
	}

	render() {
		return (
			<div className="col-12 col-md-10 news-wrapper">
				<div className="news-header">
					<h5>SPACE PIRATE</h5>
					<div className="sub-nav">
						<h6><Link to="/spacepirate/about">ABOUT</Link></h6>
						<h6><Link to="/spacepirate/read">READ</Link></h6>
						<h6><Link to="/spacepirate/characters">CHARACTERS</Link></h6>
					</div>
				</div>

				<div className="news-entry-wrapper">
					<div className="news-entry">
						<div className="news-head">
							<Switch>
								<Route path="/spacepirate/about" component={SpacePirateAbout}/>
								<Route path="/spacepirate/read"  component={SpacePirateRead}/>
								<Route path="/spacepirate/characters" component={SpacePirateCharacters}/>
								<Route path="/spacepirate/episode/:id" component={ReadComic}/>
							</Switch>
						</div>
					</div>
				</div>
				<br/>
			</div>
		);
	}

}

export default SpacePirate;