import React from 'react';
import './App.scss';
import CrookHeader from './components/crook-header.js';
import Home from './routing/home.route.js';
import Shop from './routing/shop.route.js';
import SpacePirate from './routing/spacepirate.route.js';
import {
  BrowserRouter as Router,
  Switch,
  Route, Link
} from "react-router-dom";
import SysInfo from './components/Sys-Info.component.js';
import CopyrightFooter from './components/copyright-footer.component.js';
import TermsRoute from "./routing/terms.route.js";
import PrivacyRoute from "./routing/privacy.route.js";
import AboutRoute from "./routing/about.route.js";
import {Helmet} from 'react-helmet';

function App() {
  SysInfo.getInstance().init();

  return (
    <Router>
      <div className="App">
        <Helmet
           script={[{ 
          type: 'text/javascript', 
          innerHTML: 'window._mNHandle = window._mNHandle || {}; window._mNHandle.queue = window._mNHandle.queue || []; medianet_versionId = "3121199";' 
          }]} 
          />
          <Helmet><script src="https://contextual.media.net/dmedianet.js?cid=8CU5Y7476" async="async"></script></Helmet>
  	    <CrookHeader></CrookHeader>

        <Switch>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/shop">
            <Shop />
          </Route>
          <Route path="/spacepirate">
            <SpacePirate />
          </Route>
          <Route path="/legal/terms">
            <TermsRoute/>
          </Route>
          <Route path="/privacy/policy">
            <PrivacyRoute/>
          </Route>
          <Route path="/info">
            <AboutRoute/>
          </Route>
        </Switch>

        <div className="crook-footer">
          <div className="footer-links">
            <div>
              <h6>About</h6>
              <ul>
                <li><Link to="/info">Company Info</Link></li>
              </ul>
            </div>
            <div>
              <h6>Legal</h6>
              <ul>
                <li><Link to="/legal/terms">Terms of Use</Link></li>
              </ul>
            </div>
            <div>
              <h6>Privacy</h6>
              <ul>
                <li><Link to="/privacy/policy">Privacy Policy</Link></li>
              </ul>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-center mb-2"><span>Visit our partners!</span></div>
            <div className="d-flex justify-content-center mb-3">
              <div className="mr-2">
                <a target="_blank" href="https://shareasale.com/r.cfm?b=922557&amp;u=2880821&amp;m=47&amp;urllink=&amp;afftrack="><img src="https://static.shareasale.com/image/47/ShareASale-120x60-A-light.png" border="0" /></a>
              </div>
              <div>
                <a target="_blank" href="https://shareasale.com/r.cfm?b=366652&amp;u=2880821&amp;m=30103&amp;urllink=&amp;afftrack="><img src="https://static.shareasale.com/image/30103/120x60-acrylic-wht.jpg" border="0" alt="www.dickblick.com" /></a>
              </div>
            </div>
          </div>
          <CopyrightFooter></CopyrightFooter>
        </div>
      </div>
    </Router>
  );
}

export default App;
