import React, {Component} from 'react';
import Image from 'react-bootstrap/Image';

class NewsAttachment extends Component {
	render (){
		return (<div className="attachment">
			<Image src={process.env.REACT_APP_API_URL + "/contentstream/news/attachment/" + this.props.attachment.id} thumbnail fluid/>
		</div>);
	}
}

export default NewsAttachment;