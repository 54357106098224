import React from 'react';
import CrookApi from './crook-api.component';

class NewsEntryEndpoints extends React.Component {

	static getTest(){
		return CrookApi.get('news/test');
	}

}

export default NewsEntryEndpoints;