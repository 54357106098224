import React, {Component} from 'react';
import {
  Link
} from "react-router-dom";

class CrookNavItem extends Component {
	render () {
		return (
			<div onClick={ () => {
				if(this.props.onClick){
					this.props.onClick();
				}
			} } className="nav-item"><Link to={this.props.navLink}>{this.props.label.toUpperCase()}</Link></div>
		);
	}
}

export default CrookNavItem;