import React from "react";
import ComicSeriesEndpoints from './../../components/util/comic-series-endpoints.component.js';
import Image from 'react-bootstrap/Image';
import TapasLogo from '../../assets/images/tapas.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

class SpacePirateAbout extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount(){
		ComicSeriesEndpoints.getComicSeries(1).then(resp => {
			this.setState({series : resp.data});
		});
	}

	render () {
		return (
			<div>
				{this.state.series ? <div dangerouslySetInnerHTML={{__html: this.state.series.seriesDescription}}></div> : ''}

				<div className="icon-links d-flex">
					<div><a href="https://tapas.io/series/Space-Pirate/info" target="_blank"><img src={TapasLogo} className="tapas-logo"/></a></div>
					<div className="ml-2"><a href="https://www.instagram.com/spacepiratemanga/" target="_blank"><FontAwesomeIcon icon={faInstagram} size="3x"/></a></div>
					<div className="ml-2"><a href="https://www.facebook.com/SpacePirateManga" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} size="3x"/></a></div>
				</div>
			</div>
		)
	}
}

export default SpacePirateAbout;