import React from 'react';
import EpisodeEndpoints from './../../../components/util/episode-endpoints.component.js';

class ReadComic extends React.Component {
	constructor(){
		super();
	}

	componentDidMount() {
		console.log(this.props);

		EpisodeEndpoints.getEpisodeMetadata(this.props.match.params.id).then(data => {
			let meta = data.data;
			this.setState({meta: meta});
		});
	}

	renderEpisodes(){
		let epList = [];
		if(this.state && this.state != null && this.state.meta && this.state.meta !== null){
			for(let i = 1; i < this.state.meta.numberPages; i++){
				epList.push(<img src={process.env.REACT_APP_API_URL + "/contentstream/episode/" + this.state.meta.id + "/page/" + i} loading="lazy"/>);
			}
		}

		return epList;
	}

	render () {
		return (
			<div className="read-episode">
				<div className="d-none d-md-flex justify-content-center mb-3">
					<a target="_blank" href="https://shareasale.com/r.cfm?b=290502&amp;u=2880821&amp;m=30103&amp;urllink=&amp;afftrack="><img src="https://static.shareasale.com/image/30103/framing468x60.jpg" border="0" alt="www.dickblick.com" /></a>
				</div>
				<div className="d-flex d-md-none justify-content-center mb-3">
					<a target="_blank" href="https://shareasale.com/r.cfm?b=290510&amp;u=2880821&amp;m=30103&amp;urllink=&amp;afftrack="><img src="https://static.shareasale.com/image/30103/pastels200x150.jpg" border="0" alt="www.dickblick.com" /></a>
				</div>
				<div className="episode-container">
					{this.renderEpisodes()}
				</div>
			</div>
		)
	}
}

export default ReadComic;