import React, {Component} from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

class ListingCard extends Component {

	render(){
		return (
			<Card className="sale-card-wrapper mt-3 mb-3 mr-3">
				<Card.Img variant="top" src="http://zfgc.com/forum/index.php?action=dlattach;attach=12126;type=avatar"/>
				<Card.Body>
					<Card.Title>{this.props.item.title}</Card.Title>
					<Card.Text>{this.props.item.description}</Card.Text>
					<div className="price">${this.props.item.price}</div>
					<div className="free-shipping">Free Shipping!</div>
				</Card.Body>
				<Button variant="primary" href="http://etsy.spacepiratemanga.com" target="_blank">See on Etsy</Button>
			</Card>
		);
	}

}

export default ListingCard;