import React from "react";
import Image from 'react-bootstrap/Image';

class CharacterMeta extends React.Component {
	constructor(){
		super();
	}

	componentDidMount(){

	}

	render (){
		return (
			<div className="character d-flex flex-column align-items-center">
				<h4>{this.props.character.characterName.toUpperCase()}</h4>
				<Image src={process.env.REACT_APP_API_URL + "/contentstream/charactermeta/" + this.props.character.id}/>
			</div>
		)
	}
}

export default CharacterMeta;