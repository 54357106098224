import React, {Component} from 'react';
import Image from 'react-bootstrap/Image';
import Modal from'react-bootstrap/Modal'
import testImage from '../../assets/images/cover-06.jpg';
import VizSensor from 'react-visibility-sensor';
import EpisodeEndpoints from '../util/episode-endpoints.component.js';
import ContentStreamEndpoints from '../util/content-stream-endpoints.component.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  BrowserRouter as Router,
  Switch,
  Route, Link
} from "react-router-dom";

class EpisodeEntry extends Component {

	constructor(props){
		super(props);
		this.state = {show : false, loadedPages : 0}
	}

	

	render (){
		return (
			<Link to={"episode/" + this.props.episode.id}>
				<div className="episode-link-wrapper">
					<div className="episode-icon">
						<Image className="icon" src={process.env.REACT_APP_API_URL + "/contentstream/episode/icon/" + this.props.episode.episodeIconId} thumbnail fluid/>
					</div>
					<div class="episode-deets">
						<h4 className="d-flex d-md-block flex-column">
							<span className="episode-number">#{this.props.episode.episodeNumber}</span> 
							<span className="episode-title">{this.props.episode.episodeTitle}</span>
						</h4>
						<div className="d-none d-sm-block">{this.props.episode.episodeDesc}</div>
					</div>
				</div>
			</Link>
		)
	}

}

export default EpisodeEntry;