import React from 'react';
import CrookApi from './crook-api.component.js';

class EpisodeEndpoints extends React.Component {

	static getRootEndpoint(){
		return "episode/";
	}

	static getEpisodeMetadata(episodeId){
		return CrookApi.get(this.getRootEndpoint() + episodeId + '/meta');
	}

	static getEpisodesBySeries(seriesId){
		return CrookApi.get(this.getRootEndpoint() + 'series/' + seriesId);
	}

	static getLatestEpisodeMetadata(seriesId){
		return CrookApi.get(this.getRootEndpoint() + "series/" + seriesId + "/latest/meta");
	}

}

export default EpisodeEndpoints;